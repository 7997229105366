// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-dimension-based-results-dimensions---center{text-align:center}.traitify--components-results-dimension-based-results-dimensions---clear{clear:both}.traitify--components-results-dimension-based-results-dimensions---hidden{display:none}.traitify--components-results-dimension-based-results-dimensions---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results-dimension-based-results-dimensions---dimensions{-webkit-box-sizing:border-box;box-sizing:border-box;color:#333;font:100%/1.5 \"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif;font-weight:400;margin:0;padding:0;width:100%}.traitify--components-results-dimension-based-results-dimensions---dimensions *{-webkit-box-sizing:border-box;box-sizing:border-box}.traitify--components-results-dimension-based-results-dimensions---dimensions button{border:none;border-radius:0;cursor:pointer;font-family:\"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif}.traitify--components-results-dimension-based-results-dimensions---center{text-align:center}.traitify--components-results-dimension-based-results-dimensions---hide{display:none}.traitify--components-results-dimension-based-results-dimensions---dimensions{clear:both;list-style:none;margin-bottom:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-results-dimension-based-results-dimensions---center",
	"clear": "traitify--components-results-dimension-based-results-dimensions---clear",
	"hidden": "traitify--components-results-dimension-based-results-dimensions---hidden",
	"visually-hidden": "traitify--components-results-dimension-based-results-dimensions---visually-hidden",
	"dimensions": "traitify--components-results-dimension-based-results-dimensions---dimensions",
	"hide": "traitify--components-results-dimension-based-results-dimensions---hide"
};
export default ___CSS_LOADER_EXPORT___;
