// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-dimension-based-results-personality-heading---center{text-align:center}.traitify--components-results-dimension-based-results-personality-heading---clear{clear:both}.traitify--components-results-dimension-based-results-personality-heading---hidden{display:none}.traitify--components-results-dimension-based-results-personality-heading---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results-dimension-based-results-personality-heading---personality{-webkit-box-sizing:border-box;box-sizing:border-box;color:#333;font:100%/1.5 \"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif;font-weight:400;margin:0;padding:0;width:100%}.traitify--components-results-dimension-based-results-personality-heading---personality *{-webkit-box-sizing:border-box;box-sizing:border-box}.traitify--components-results-dimension-based-results-personality-heading---personality button{border:none;border-radius:0;cursor:pointer;font-family:\"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif}.traitify--components-results-dimension-based-results-personality-heading---center{text-align:center}.traitify--components-results-dimension-based-results-personality-heading---hide{display:none}.traitify--components-results-dimension-based-results-personality-heading---personality{clear:both;margin:20px auto}.traitify--components-results-dimension-based-results-personality-heading---personality .traitify--components-results-dimension-based-results-personality-heading---content{background:#005b82;color:#fff;text-align:center;padding:20px 10px;font-size:36px;font-size:2.25rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-results-dimension-based-results-personality-heading---center",
	"clear": "traitify--components-results-dimension-based-results-personality-heading---clear",
	"hidden": "traitify--components-results-dimension-based-results-personality-heading---hidden",
	"visually-hidden": "traitify--components-results-dimension-based-results-personality-heading---visually-hidden",
	"personality": "traitify--components-results-dimension-based-results-personality-heading---personality",
	"hide": "traitify--components-results-dimension-based-results-personality-heading---hide",
	"content": "traitify--components-results-dimension-based-results-personality-heading---content"
};
export default ___CSS_LOADER_EXPORT___;
