// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-slide-deck-personality---center{text-align:center}.traitify--components-slide-deck-personality---clear{clear:both}.traitify--components-slide-deck-personality---hidden{display:none}.traitify--components-slide-deck-personality---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-slide-deck-personality---widgetContainer{-webkit-box-sizing:border-box;box-sizing:border-box;color:#333;font:100%/1.5 \"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif;font-weight:400;margin:0;padding:0;width:100%}.traitify--components-slide-deck-personality---widgetContainer *{-webkit-box-sizing:border-box;box-sizing:border-box}.traitify--components-slide-deck-personality---widgetContainer button{border:none;border-radius:0;cursor:pointer;font-family:\"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif}.traitify--components-slide-deck-personality---center{text-align:center}.traitify--components-slide-deck-personality---hide{display:none}.traitify--components-slide-deck-personality---widgetContainer{position:relative;min-height:100vh}@media(min-width: 48em){.traitify--components-slide-deck-personality---widgetContainer{min-height:600px}}.traitify--components-slide-deck-personality---widgetContainer:-webkit-full-screen{position:fixed;top:0;bottom:0}.traitify--components-slide-deck-personality---widgetContainer:-ms-fullscreen{position:fixed;top:0;bottom:0}.traitify--components-slide-deck-personality---widgetContainer:fullscreen{position:fixed;top:0;bottom:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-slide-deck-personality---center",
	"clear": "traitify--components-slide-deck-personality---clear",
	"hidden": "traitify--components-slide-deck-personality---hidden",
	"visually-hidden": "traitify--components-slide-deck-personality---visually-hidden",
	"widgetContainer": "traitify--components-slide-deck-personality---widgetContainer",
	"hide": "traitify--components-slide-deck-personality---hide"
};
export default ___CSS_LOADER_EXPORT___;
