// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-cognitive-results---center{text-align:center}.traitify--components-results-cognitive-results---clear{clear:both}.traitify--components-results-cognitive-results---hidden{display:none}.traitify--components-results-cognitive-results---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results-cognitive-results---results{-webkit-box-sizing:border-box;box-sizing:border-box;color:#333;font:100%/1.5 \"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif;font-weight:400;margin:0;padding:0;width:100%}.traitify--components-results-cognitive-results---results *{-webkit-box-sizing:border-box;box-sizing:border-box}.traitify--components-results-cognitive-results---results button{border:none;border-radius:0;cursor:pointer;font-family:\"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif}.traitify--components-results-cognitive-results---center{text-align:center}.traitify--components-results-cognitive-results---hide{display:none}.traitify--components-results-cognitive-results---results{padding:0 20px;text-align:center;font-size:18px;font-size:1.125rem}.traitify--components-results-cognitive-results---results a,.traitify--components-results-cognitive-results---results a:visited{color:#3b8dbf;text-decoration:none}.traitify--components-results-cognitive-results---results img{margin:0 auto;width:50%;max-width:200px}.traitify--components-results-cognitive-results---results h1{color:#3b8dbf;margin-top:0;font-size:26px;font-size:1.625rem}.traitify--components-results-cognitive-results---results p{font-size:15px;font-size:0.9375rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-results-cognitive-results---center",
	"clear": "traitify--components-results-cognitive-results---clear",
	"hidden": "traitify--components-results-cognitive-results---hidden",
	"visually-hidden": "traitify--components-results-cognitive-results---visually-hidden",
	"results": "traitify--components-results-cognitive-results---results",
	"hide": "traitify--components-results-cognitive-results---hide"
};
export default ___CSS_LOADER_EXPORT___;
