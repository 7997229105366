// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-personality-dimension-list---center{text-align:center}.traitify--components-results-personality-dimension-list---clear{clear:both}.traitify--components-results-personality-dimension-list---hidden{display:none}.traitify--components-results-personality-dimension-list---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results-personality-dimension-list---container{-webkit-box-sizing:border-box;box-sizing:border-box;color:#333;font:100%/1.5 \"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif;font-weight:400;margin:0;padding:0;width:100%}.traitify--components-results-personality-dimension-list---container *{-webkit-box-sizing:border-box;box-sizing:border-box}.traitify--components-results-personality-dimension-list---container button{border:none;border-radius:0;cursor:pointer;font-family:\"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif}.traitify--components-results-personality-dimension-list---center{text-align:center}.traitify--components-results-personality-dimension-list---hide{display:none}.traitify--components-results-personality-dimension-list---container ul{list-style:none;margin:0;padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-results-personality-dimension-list---center",
	"clear": "traitify--components-results-personality-dimension-list---clear",
	"hidden": "traitify--components-results-personality-dimension-list---hidden",
	"visually-hidden": "traitify--components-results-personality-dimension-list---visually-hidden",
	"container": "traitify--components-results-personality-dimension-list---container",
	"hide": "traitify--components-results-personality-dimension-list---hide"
};
export default ___CSS_LOADER_EXPORT___;
