// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-dimension-based-results-personality-types---center{text-align:center}.traitify--components-results-dimension-based-results-personality-types---clear{clear:both}.traitify--components-results-dimension-based-results-personality-types---hidden{display:none}.traitify--components-results-dimension-based-results-personality-types---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results-dimension-based-results-personality-types---types{-webkit-box-sizing:border-box;box-sizing:border-box;color:#333;font:100%/1.5 \"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif;font-weight:400;margin:0;padding:0;width:100%}.traitify--components-results-dimension-based-results-personality-types---types *{-webkit-box-sizing:border-box;box-sizing:border-box}.traitify--components-results-dimension-based-results-personality-types---types button{border:none;border-radius:0;cursor:pointer;font-family:\"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif}.traitify--components-results-dimension-based-results-personality-types---center{text-align:center}.traitify--components-results-dimension-based-results-personality-types---hide{display:none}.traitify--components-results-dimension-based-results-personality-types---types{list-style:none}@media(min-width: 60em){.traitify--components-results-dimension-based-results-personality-types---types{float:left;padding:0 20px;width:50%;-webkit-box-sizing:border-box;box-sizing:border-box}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-results-dimension-based-results-personality-types---center",
	"clear": "traitify--components-results-dimension-based-results-personality-types---clear",
	"hidden": "traitify--components-results-dimension-based-results-personality-types---hidden",
	"visually-hidden": "traitify--components-results-dimension-based-results-personality-types---visually-hidden",
	"types": "traitify--components-results-dimension-based-results-personality-types---types",
	"hide": "traitify--components-results-dimension-based-results-personality-types---hide"
};
export default ___CSS_LOADER_EXPORT___;
