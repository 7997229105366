(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react-dom"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define("Traitify", ["react-dom", "react"], factory);
	else if(typeof exports === 'object')
		exports["Traitify"] = factory(require("react-dom"), require("react"));
	else
		root["Traitify"] = factory(root["ReactDOM"], root["React"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__5156__, __WEBPACK_EXTERNAL_MODULE__9787__) {
return 