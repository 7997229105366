// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-personality-trait-list---title:before,.traitify--components-results-personality-trait-list---title:after{content:\" \";display:table}.traitify--components-results-personality-trait-list---title:after{clear:both}.traitify--components-results-personality-trait-list---center{text-align:center}.traitify--components-results-personality-trait-list---clear{clear:both}.traitify--components-results-personality-trait-list---hidden{display:none}.traitify--components-results-personality-trait-list---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results-personality-trait-list---traits{-webkit-box-sizing:border-box;box-sizing:border-box;color:#333;font:100%/1.5 \"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif;font-weight:400;margin:0;padding:0;width:100%}.traitify--components-results-personality-trait-list---traits *{-webkit-box-sizing:border-box;box-sizing:border-box}.traitify--components-results-personality-trait-list---traits button{border:none;border-radius:0;cursor:pointer;font-family:\"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif}.traitify--components-results-personality-trait-list---center{text-align:center}.traitify--components-results-personality-trait-list---hide{display:none}.traitify--components-results-personality-trait-list---traits{font-weight:400;color:#333;margin-bottom:40px}.traitify--components-results-personality-trait-list---title{font-weight:normal;text-transform:uppercase;font-size:18px;font-size:1.125rem}@media(min-width: 71.25em){.traitify--components-results-personality-trait-list---title{font-size:20px;font-size:1.25rem}}.traitify--components-results-personality-trait-list---traitsDefinition{padding-bottom:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "traitify--components-results-personality-trait-list---title",
	"center": "traitify--components-results-personality-trait-list---center",
	"clear": "traitify--components-results-personality-trait-list---clear",
	"hidden": "traitify--components-results-personality-trait-list---hidden",
	"visually-hidden": "traitify--components-results-personality-trait-list---visually-hidden",
	"traits": "traitify--components-results-personality-trait-list---traits",
	"hide": "traitify--components-results-personality-trait-list---hide",
	"traitsDefinition": "traitify--components-results-personality-trait-list---traitsDefinition"
};
export default ___CSS_LOADER_EXPORT___;
